.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}

.ant-select-selector {
    height: 28px !important;
}

// Down Icon
span.anticon-down svg {
    margin-top: -2px;
    font-size: 18px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 35px !important;
}