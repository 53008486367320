input.ant-input,
.ant-picker,
.ant-input-affix-wrapper,
input.ant-input-number-input,
input.ant-select-selection-search-input {
    border: none !important;
    border-radius: 0;
    padding: 8px 16px !important;
    background-color: #E3E3E3 !important;
}

.ant-picker,
.ant-input-number,
.ant-select-selector,
input.ant-input-number-input,
input.ant-select-selection-search-input {
    height: 38px !important;
}

.ant-form-item-has-error .ant-input-affix-wrapper {
    .ant-input,
    .ant-input:focus {
        box-shadow: none !important;
    }
}
.ant-form-item-has-error {
    .ant-input,
    .ant-input:focus,
    .ant-input-number,
    .ant-picker,
    .ant-input-affix-wrapper {
        border: none;
        border-radius: 0;
        box-shadow: 0 0 0 2px #ff4d4f !important;
    }
}
.ant-select-focused .ant-select-selector {
    border: none !important;
    box-shadow: 0 0 0 2px #0f62fe !important;
}
.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-affix-wrapper-focused {
    border: none;
    border-radius: 0 !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #0f62fe !important;
}
.ant-input-affix-wrapper {
    border: none !important;
    padding: 0 11px 0 0 !important;
}
.ant-input-number:hover,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper:hover {
    border: none;
    box-shadow: none;
    border-right-width: unset;
}
div.ant-picker-focused,
input.ant-input:focus, input.ant-input:focus-visible {
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #0f62fe;
    border-radius: 0;
}

.ant-form-vertical .ant-form-item-label {
    padding: 0 !important;
}

.ant-input-number-handler-wrap { 
    visibility: hidden;
}

/* Chrome autofill color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #E3E3E3 inset !important;
}

// Autocomplete
.ant-select-selector {
    padding: 0 !important;
    border: none !important;
    .ant-select-selection-search {
        width: 100%;
        right: 0 !important;
        left: 0 !important;
    }
}

// Ant Picker
.ant-picker-clear svg {
    background-color: #E3E3E3 !important;
}