@import '../../App.scss';
.login-modal {
    .ant-modal-header {
        padding-left: 55px !important;
        padding-right: 55px !important;
    }
    .ant-modal-body {
        padding: 24px 55px;
    }
}
.login-tab-modal {
    .ant-modal-header {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        .ant-tabs-nav {
            width: 100%;
            .ant-tabs-nav-list {
                width: 100%;
                .ant-tabs-tab {
                    text-align: center;
                    width: 50%;
                    justify-content: center;
                    .ant-tabs-tab-btn {
                        color: $jobster-gray;
                        font-size: 20px;
                        font-weight: 500;
                    }
                }
                .ant-tabs-tab:hover {
                    color: $jobster-blue;
                }
                .ant-tabs-ink-bar {
                    background-color: $jobster-blue;
                    height: 4px;
                }
            }
            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: $jobster-blue;
                font-weight: 700;
            }
        }
    }
}