@import '../../App.scss';
.modal-with-alert {
    .ant-modal-header {
        .ant-modal-title {
            color: $jobster-gray;
        }
    }
    
    .ant-modal-header, .ant-modal-footer {
        border: none !important;
        text-align: center !important;
    }

    .ant-modal-body {
        position: relative;
        margin-bottom: 50px;
    }

    .modal-alert-container {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 100;
    }
}