$jobster-blue: #2E3C4D;
$jobster-light-blue: #A8C1DE;
$jobster-gray: #939393;
$jobster-gold: #D1B38D;
$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 40px;
$font-size-xxl: 48px;
@import './input.scss';
@import './components/buttons.scss';

html {
  scroll-behavior: smooth;
}

body {
  color: $jobster-blue !important;
}

.max-width-2400 {
  max-width: 2400px;
}

a:hover {
  text-decoration: none !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.position-inherit {
  position: inherit !important;
}

.text-blue-with-hover:hover {
  color: $jobster-light-blue !important;
}

.text-blue {
  color: $jobster-blue !important;
}

.text-gray {
  color: $jobster-gray !important;
}

.text-gold {
  color: $jobster-gold !important;
}

.border-blue {
  border-color: $jobster-blue !important;
}

.bg-blue {
  background-color: $jobster-blue;
  color: white;
}

.bg-gray {
  background-color: #F0F0F0;
}

.bg-gold {
  background-color: $jobster-gold;
}

.font-size-biggest {
  font-size: 5.6vw;
}
.font-size-bigger {
  font-size: 4.8vw;
}
.font-size-big {
  font-size: 4vw;
}
.font-size-normal {
  font-size: 3.2vw;
}
.font-size-small {
  font-size: 2.4vw;
}
.font-size-smaller {
  font-size: 1.6vw;
}
.font-size-smallest {
  font-size: 0.8vw;
}
.font-size-tiny {
  font-size: 0.2vw;
}

.font-md {
  font-size: $font-size-md !important;
}

.font-sm {
  font-size: $font-size-sm !important;
}

.font-lg {
  font-size: $font-size-lg !important;
}

.font-xl {
  font-size: $font-size-xl !important;
}

.font-xxl {
  font-size: $font-size-xxl !important;
}

.jobster-font-weight-800 {
  font-weight: 800 !important;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .font-size-biggest {
    font-size: 10vw;
  }
  .font-size-bigger {
    font-size: 9vw;
  }
  .font-size-big {
    font-size: 8vw;
  }
  .font-size-normal {
    font-size: 7vw;
  }
  .font-size-small {
    font-size: 6vw;
  }
  .font-size-smaller {
    font-size: 5vw;
  }
  .font-size-smallest {
    font-size: 4vw;
  }
  .font-size-tiny {
    font-size: 3vw;
  }
}

// Ant dragger
.ant-upload.ant-upload-drag span.ant-upload {
  padding: 0 0 30px;
}

// Ant alert
.ant-alert-success {
  background-color: #ECF8E9;
  .ant-alert-message {
    color: #378224;
  }
}

.ant-alert.ant-alert-error {
  background-color: #FFEFEF;
  .ant-alert-message {
    color: #CE4242;
  }
}

// Ant tab
.ant-tabs-top > .ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-top.without-border > .ant-tabs-nav::before {
  border: none !important;
}

.ant-tabs-top.sub-tab {
  .ant-tabs-tabpane {
    padding: 10px 20px;
  }
  .ant-tabs-nav-wrap {
    padding: 0 20px;
  }
}

// Ant tag
.ant-tag {
  margin-bottom: 8px !important;
  svg {
    vertical-align: baseline;
  }
}
.ant-tag {
  margin-bottom: 8px !important;
}

// Pagination
.ant-pagination-item-active a {
  color: #1890ff !important;
}

.ant-pagination-item {
  margin: 0 !important;
  border: none !important;
  a {
      margin-top: 3px !important;
  }
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin: 0 !important;
  button {
      border: none !important;
  }
}

.ant-table-pagination.ant-pagination {
  margin-top: 0 !important;
}

// Progress
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: black !important;
}

.fade-out-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 1000ms, transform 1000ms;
}

// Collapse
.ant-collapse-header {
  padding-inline-start: 0 !important;
}
.ant-collapse-expand-icon {
  margin: 0 !important;
}
.ant-collapse-content-box {
  padding: 0 !important;
}

// Section Margin
.section-margin {
  margin-top: 84px;
  margin-bottom: 84px;
}