.edit-interview .ant-select-selector {
    border: none !important;
    border-radius: 0 !important;
    background-color: #E3E3E3 !important;
    height: 38px !important;
    padding: 0 18px !important;
}
.edit-interview .ant-select-selector .ant-select-selection-item {
    margin: auto;
}

.anticon-swap-right svg {
    margin-top: -10px !important;
}

textarea {
    border: none !important;
    border-radius: 0 !important;
    padding: 8px 16px !important;
    background-color: #E3E3E3 !important;
}
textarea.ant-input:focus {
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #0f62fe;
}